<template>
  <b-modal
    id="financeiroCliente"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    :title="'Financeiro ' + clienteDados.cliente"
  >
    <b-row>
      <b-col>
        <div class="d-flex">
          <feather-icon class="text-success" icon="TrendingUpIcon" size="19" />
          <h5 class="mb-0 ml-50 text-success">Cobranças</h5>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1 px-1">
      <b-table responsive small hover :items="cobrancas" :fields="fields">
        <template #cell(vencimento_original)="data">
          {{ data.item.vencimento_original | converteData }}
        </template>
        <template #cell(data_vencimento)="data">
          {{ data.item.data_vencimento | converteData }}
        </template>

        <template #cell(data_pagamento)="data">
          {{ data.item.data_pagamento | converteData }}
        </template>

        <template #cell(valor)="data">
          {{ data.item.valor | formataMoeda }}
        </template>

        <template #cell(situacao)="data">
          <b-badge :variant="data.item.cor"> {{ data.item.situacao }}</b-badge>
        </template>

        <template #cell(opcoes)="data">
          <b-button
            class="btn-icon"
            variant="flat-secondary"
            size="sm"
            v-b-popover.hover="'Imprimir Boleto'"
            @click="imprimirBoleto(data.item.link)"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
        </template>
      </b-table>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BRow,
  BCol,
  BBadge,
  BButton,
  VBPopover,
} from "bootstrap-vue";
export default {
  props: ["clienteDados", "financeiroCliente"],
  components: {
    BModal,
    BCard,
    BTable,
    BRow,
    BCol,
    BBadge,
    BButton,
  },

  directives: { "b-popover": VBPopover },

  data() {
    return {
      items: [],
      fields: [
        {
          key: "vencimento_original",
          label: "Venc Original",
          thStyle: { width: 100 },
        },
        { key: "data_vencimento", label: "Venc", thAttr: { width: 110 } },
        { key: "forma", label: "Forma Pagamento" },
        { key: "descricao", label: "Descrição" },
        { key: "valor", label: "Valor" },
        { key: "data_pagamento", label: "Pagamento" },
        { key: "situacao", label: "Situacão" },
        { key: "opcoes", label: "Opções" },
      ],
      situacaoAsaas: {
        PENDING: { texto: "Aguardando pagamento", cor: "light-primary" },
        RECEIVED: { texto: "Pago", cor: "light-success" },
        CONFIRMED: { texto: "Pago", cor: "light-success" },
        OVERDUE: { texto: "Vencida", cor: "light-danger" },
        REFUNDED: { texto: "Estornada", cor: "light-warning" },
        RECEIVED_IN_CASH: { texto: "Recebimento Direto", cor: "light-success" },
        REFUND_REQUESTED: { texto: "Estorno Solicitado", cor: "light-warning" },
        CHARGEBACK_REQUESTED: { texto: "Chargeback", cor: "light-danger" },
        CHARGEBACK_DISPUTE: {
          texto: "Em disputa Chargeback",
          cor: "light-danger",
        },
        AWAITING_CHARGEBACK_REVERSAL: {
          texto: "Disputa Vencida",
          cor: "light-warning",
        },
        DUNNING_RECEIVED: { texto: "Recuperada", cor: "light-success" },
        AWAITING_RISK_ANALYSIS: {
          texto: "Pagamento em análise",
          cor: "light-warning",
        },
      },
    };
  },
  computed: {
    cobrancas() {
      if (!this.financeiroCliente.length) return [];
      let result = [];
      this.financeiroCliente.forEach((v) => {
        result.push({
          ...v,
          situacao: this.situacaoAsaas[v.status].texto,
          cor: this.situacaoAsaas[v.status].cor,
          forma: this.forma(v),
        });
      });
      return result;
    },
  },
  methods: {
    forma(v) {
      return v.tipo_cobranca === 2 ? "Boleto" : "Cartão de Crédito";
    },

    imprimirBoleto(link) {
      if (link) {
        window.open(link);
      }
    },
  },
  filters: {
    converteData(data) {
      if (!data) return;

      const d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    converteDataHora(data) {
      if (!data) return;
      return moment(data).format("DD/MM/YYY H:m");
    },
    formataMoeda(valor) {
      if (!valor) return "0,00";
      return Number(valor).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style>
</style>