<template>
  <b-modal
    id="historicoSuporte"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    :title="'Histórico ' + clienteDados.cliente"
  >
    <b-card>
      <b-row class="mb-1">
        <b-col md="3">
          <label>Assunto:</label>
          <b-form-input v-model="assunto" />
        </b-col>

        <b-col>
          <label>Descrição:</label>
          <b-form-input v-model="descricao" />
        </b-col>

        <b-col md="auto" style="padding-top: 23px; padding-left: 0px">
          <b-button
            variant="outline-primary"
            v-model="concluido"
            @click="concluido = !concluido"
          >
            <feather-icon v-if="concluido" icon="CheckSquareIcon" />
            <feather-icon v-else icon="SquareIcon" />
            Conluído
          </b-button>
        </b-col>

        <b-col md="auto" style="padding-top: 23px; padding-left: 0px">
          <b-button variant="primary" class="float-right" @click="cadastrar">
            Enviar
          </b-button>
        </b-col>
      </b-row>

      <b-table
        striped
        hover
        small
        :items="atendimentos"
        :fields="fields"
        responsive="sm"
      >
        <template #cell(data)="data">
          {{ data.item.data | formataDataHora }}
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.concluido" variant="success"
            >Concluído</b-badge
          >
          <b-badge
            v-else
            variant="light-warning"
            @click="abreModalConcluir(data.item, data.index)"
            >Pendente</b-badge
          >
        </template>
        <template #row-details="row">
          <b-card border-variant="secondary">
            <div class="d-flex mb-2">
              <feather-icon
                class="text-success"
                icon="TrendingUpIcon"
                size="19"
              />
              <h5 class="mb-0 ml-50 text-success">Detalhes</h5>
            </div>
            <b-row class="mb-2">
              <b-col>
                <strong> Descrição: </strong>
                <span>
                  {{ row.item.descricao }}
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <strong> Atendimento: </strong>
                <span>
                  {{ row.item.suporte_nome }}
                </span>
              </b-col>
              <b-col v-if="row.item.concluido">
                <strong> Concluído: </strong>
                <span>
                  {{ row.item.concluido | formataDataHora }}
                </span>
              </b-col>
            </b-row>
            <hr />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
              class="float-right"
            >
              Fechar Detalhes
            </b-button>
          </b-card>
        </template>
        <template #cell(opcoes)="data">
          <b-button
            v-if="data.item.id"
            class="btn-icon"
            variant="flat-secondary"
            @click="data.toggleDetails"
            v-model="data.detailsShowing"
            style="padding: 2px"
          >
            <feather-icon
              :class="data.detailsShowing ? 'text-primary' : 'text-default'"
              icon="EyeIcon"
              size="16"
            />
          </b-button>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupAppend,
  BButton,
  BRow,
  BCol,
  BBadge,
  BFormCheckbox,
  VBModal,
} from "bootstrap-vue";
import { mapState } from "vuex";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: ["clienteDados"],
  components: {
    BModal,
    BCard,
    BTable,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      assunto: "",
      descricao: "",
      concluido: false,
      boxTwo: "",
      fields: [
        { key: "id", label: "ID" },
        { key: "assunto", label: "Assunto" },
        { key: "data", label: "Data/Hora" },
        { key: "status", label: "Status" },
        { key: "opcoes", label: "Opções" },
      ],
    };
  },

  computed: {
    ...mapState({
      me: (state) => state.user.user,
      atendimentos: (state) => state.atendimento.atendimentos,
    }),
  },
  methods: {
    cadastrar() {
      let payload = {
        id_company: this.clienteDados.id_company,
        user_id: this.me.id,
        assunto: this.assunto,
        descricao: this.descricao,
        concluido: this.concluido,
      };
      this.$http.post("adm/atendimento", payload).then((res) => {
        if (res.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Atendimento lançado com Sucesso!",
              icon: "InfoIcon",
              variant: "success",
            },
          });
          this.$store.dispatch(
            "atendimento/listaAtendimentos",
            this.clienteDados.id_company
          );
          this.limpaDados();
        }
      });
    },
    abreModalConcluir(item, index) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Deseja realmente concluir esse atendimento?", {
          title: "Concluir Atendimento",
          size: "sm",
          okVariant: "success",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.boxTwo = value;
            this.$http
              .put("adm/atendimento/concluir/" + item.id)
              .then((res) => {
                if (res.data) {
                  item.concluido = this.$getDate.dataHoraHoje();
                  let response = {
                    index: index,
                    dados: item,
                  };
                  this.$store.commit(
                    "atendimento/EDITAR_ATENDIMENTO",
                    response
                  );
                }
              });
          }
        });
    },
    limpaDados() {
      (this.assunto = ""), (this.descricao = "");
      this.concluido = false;
    },
  },
  filters: {
    formataDataHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style>
</style>