<template>
  <b-modal
    id="migracao"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="xl"
    :title="'Migração ' + clienteDados.cliente"
  >
    <b-table hover striped responsive :items="migracao" :fields="fields">
      <template #cell(data)="data">
        {{ data.item.data | converteData }}
      </template>
      <template #cell(status)="data">
        <b-badge v-if="data.item.status === 1" variant="warning">Pendente</b-badge>
        <b-badge v-else variant="success">Concluído</b-badge>
      </template>
      <template #cell(options)="data">
        <b-button
          v-if="data.item.id"
          class="btn-icon"
          variant="flat-secondary"
          @click="data.toggleDetails"
          v-model="data.detailsShowing"
          style="padding: 2px"
        >
          <feather-icon
            :class="data.detailsShowing ? 'text-primary' : 'text-default'"
            icon="EyeIcon"
            size="16"
          />
        </b-button>
      </template>
      <template #row-details="row">
        <b-card border-variant="secondary">
          <div class="d-flex mb-2">
            <feather-icon
              class="text-success"
              icon="TrendingUpIcon"
              size="19"
            />
            <h5 class="mb-0 ml-50 text-success">Detalhes Migração</h5>
          </div>
          <b-row class="mb-2">
            <b-col md="4">
              <strong> Opções: </strong>
              <span>
                {{ row.item.opcoes }}
              </span>
            </b-col>
          </b-row>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
            class="my-1"
          >
            Fechar Detalhes
          </b-button>
        </b-card>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { BModal, BTable, BButton, BRow, BCol, BCard, BBadge, } from "bootstrap-vue";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    BModal,
    BTable,
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
  },
  props: ["clienteDados"],

  data() {
    return {
      fields: [
        { key: "id_company", label: "Empresa ID" },
        { key: "data", label: "Data Migração" },
        { key: "email_usuario_v1", label: "Email Usuário V1" },
        { key: "status", label: "Status" },
        { key: "options", label: "Opções" },
      ],
    };
  },

  computed: {
    ...mapState({
      migracao: (state) => state.migracao.migracao,
    }),
  },
  filters: {
    converteData(data) {
      if (!data) return;
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
</style>