<template>
  <div style="height: inherit">
    <div class="body-content-overlay" />
    <div class="todo-app-list">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <b-table
          striped
          hover
          small
          responsive
          :items="clientes.data"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filtroPesquisa"
          :filter-included-fields="filterOn"
          selectable
          select-mode="multi"
          ref="selectableTable"
          @row-selected="onRowSelected"
          selected-variant="success"
          :no-select-on-click="selecionavel"
          :busy="busy"
          show-empty
        >
          <template #empty>
            <h4 class="text-center text-primary">
              nenhum resultado encontrado
            </h4>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Aguarde...</strong>
            </div>
          </template>
          <template v-slot:head(checkbox)>
            <span> Todos</span>
            <b-form-checkbox class="ml-1" @change="selecionaTodos" />
          </template>

          <template #cell(checkbox)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true" class="ml-1">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>

          <template #cell(nome)="data">
            <span>
              {{ data.item.nome }}
            </span>
          </template>
          <template #cell(tipo)="data">
            <span v-if="data.item.tipo === null"> Teste Grátis </span>
            <span v-else-if="data.item.tipo === 1"> Mensal </span>
            <span v-else> Anual </span>
          </template>

          <template #cell(data_inicio)="data">
            <span>
              {{ data.item.data_inicio | formataData }}
            </span>
          </template>
          <template #cell(data_expiracao)="data">
            <span>
              {{ data.item.data_expiracao | formataData }}
            </span>
          </template>
          <template #cell(conta)="data">
            <span
              v-if="
                Date.parse(data.item.data_expiracao) <
                Date.parse($getDate.hoje())
              "
            >
              <b-badge variant="danger">Vencida</b-badge>
            </span>
            <span v-else>
              <b-badge variant="success">Ativa</b-badge>
            </span>
          </template>

          <template #row-details="row">
            <b-card border-variant="secondary">
              <div class="d-flex mb-2">
                <feather-icon
                  class="text-success"
                  icon="TrendingUpIcon"
                  size="19"
                />
                <h5 class="mb-0 ml-50 text-success">Detalhes Assinatura</h5>
              </div>
              <b-row class="mb-2">
                <b-col md="4">
                  <strong> Tel. Comercial: </strong>
                  <span>
                    {{ row.item.tel_comercial }}
                  </span>
                </b-col>
                <b-col md="4">
                  <strong> Celular: </strong>
                  <span>
                    {{ row.item.celular_um }}
                  </span>
                </b-col>
                <b-col md="4">
                  <strong> Whatsapp: </strong>
                  <span>
                    {{ row.item.celular_dois }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="4">
                  <strong> Dia Cobrança: </strong>
                  <span>
                    {{ row.item.dia_cobranca | formataDiaMes }}
                  </span>
                </b-col>

                <b-col md="4">
                  <strong> Valor: </strong>
                  {{ row.item.valor | formataMoeda }}
                </b-col>

                <b-col md="4">
                  <strong> Desconto: </strong>
                  {{ row.item.valor_desconto | formataMoeda }}
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="4">
                  <strong> Usuários Plano: </strong>
                  {{ row.item.usuarios_plano }}
                </b-col>

                <b-col md="4" v-if="row.item.usuarios_adicionais">
                  <strong> Usuários Adicionais: </strong>
                  {{ row.item.usuarios_adicionais }}
                </b-col>

                <b-col md="4" v-if="row.item.data_cancelamento">
                  <strong>Cancelamento: </strong>
                  {{ row.item.data_cancelamento | formataData }}
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="4" v-if="row.item.data_upgrade">
                  <strong>Upgrade: </strong>
                  {{ row.item.data_upgrade | formataData }}
                </b-col>
              </b-row>

              <hr />

              <b-row>
                <b-col>
                  <b-button
                    block
                    size="sm"
                    variant="primary"
                    class="my-1"
                    @click="abrirModalLogar"
                  >
                    <feather-icon icon="LogInIcon" class="mr-50" />
                    <span class="align-middle">Logar</span>
                  </b-button>
                  <b-modal ref="modal-logar" hide-footer title="Logar" centered>
                    <b-alert
                      :variant="getErrors.color"
                      v-if="getErrors.hasMessage"
                      show
                    >
                      <div
                        class="alert-body"
                        v-for="(error, index) in getErrors.messageArray"
                        :key="index"
                      >
                        {{ error[0] }}
                      </div>

                      <div class="alert-body">{{ getErrors.message }}</div>
                    </b-alert>
                    <div class="d-block text-center">
                      <p>
                        Deseja realmente logar na conta
                        <b>{{ row.item.cliente }}</b> ?
                      </p>
                    </div>
                    <hr />
                    <b-row align-h="end">
                      <b-button
                        class="mr-1"
                        variant="outline-secondary"
                        @click="fecharModalLogar"
                      >
                        Não
                      </b-button>
                      <b-button
                        class="mr-1"
                        variant="primary"
                        @click="logarConta(row.item)"
                      >
                        Sim
                      </b-button>
                    </b-row>
                  </b-modal>
                </b-col>
                <b-col>
                  <b-button
                    block
                    size="sm"
                    variant="primary"
                    class="my-1"
                    @click="abrirModalUsuarios(row.item)"
                  >
                    <feather-icon icon="UsersIcon" class="mr-50" />
                    <span class="align-middle">Usuários</span>
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    block
                    size="sm"
                    variant="primary"
                    class="my-1"
                    @click="abrirModalFinanceiro(row.item)"
                  >
                    <feather-icon icon="DollarSignIcon" class="mr-50" />
                    <span class="align-middle">Financeiro</span>
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    block
                    size="sm"
                    variant="primary"
                    class="my-1"
                    @click="abrirModalHistorico(row.item)"
                  >
                    <feather-icon icon="AlignLeftIcon" class="mr-50" />
                    <span class="align-middle">Histórico Suporte</span>
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    block
                    size="sm"
                    variant="primary"
                    class="my-1"
                    @click="abrirModalMigracao(row.item)"
                  >
                    <feather-icon icon="CornerUpRightIcon" class="mr-50" />
                    <span class="align-middle">Migração</span>
                  </b-button>
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
                class="my-1"
              >
                Fechar Detalhes
              </b-button>
            </b-card>
          </template>

          <template #cell(opcoes)="data">
            <b-button
              v-if="data.item.id"
              class="btn-icon"
              variant="flat-secondary"
              @click="data.toggleDetails"
              v-model="data.detailsShowing"
              style="padding: 2px"
            >
              <feather-icon
                :class="data.detailsShowing ? 'text-primary' : 'text-default'"
                icon="EyeIcon"
                size="16"
              />
            </b-button>

            <b-button
              @click="liberarCliente(data.item, data.index)"
              v-if="
                Date.parse(data.item.data_expiracao) <
                Date.parse($getDate.hoje())
              "
              class="btn-icon"
              variant="flat-secondary"
              style="padding: 2px"
            >
              <feather-icon icon="LockIcon" size="16" />
            </b-button>
            <b-button
              v-else
              class="btn-icon"
              variant="flat-secondary"
              style="padding: 2px"
            >
              <feather-icon icon="UnlockIcon" size="16" />
            </b-button>
            <b-button
              class="btn-icon"
              variant="flat-secondary"
              style="padding: 2px"
              @click="abrirWhatsapp(data.item)"
            >
              <feather-icon
                class="text-success"
                icon="MessageCircleIcon"
                size="16"
              />
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="clientes.total"
          :per-page="perPage"
          align="center"
          @input="paginacao"
        />
      </vue-perfect-scrollbar>
    </div>
    <ModalUsuarios
      :clienteDados="clienteDados"
      :usuariosCliente="usuariosCliente"
    />
    <ModalFinanceiroCliente
      :clienteDados="clienteDados"
      :financeiroCliente="financeiroCliente"
    />
    <modal-historico-suporte :clienteDados="clienteDados" />
    <modal-migracao :clienteDados="clienteDados" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BAlert,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BPagination,
  BRow,
  BTable,
  VBPopover,
  BPopover,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ModalUsuarios from "./ModalUsuarios.vue";
import ModalFinanceiroCliente from "./ModalFinanceiroCliente.vue";
import ModalHistoricoSuporte from "./ModalHistoricoSuporte.vue";
import ModalMigracao from "./ModalMigracao.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BAlert,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCard,
    BFormCheckbox,
    ToastificationContent,
    StatisticCardHorizontal,
    VuePerfectScrollbar,
    VBPopover,
    BPopover,
    BListGroup,
    BListGroupItem,
    BSpinner,
    ModalUsuarios,
    ModalFinanceiroCliente,
    ModalHistoricoSuporte,
    ModalMigracao,
  },
  directives: {
    "b-popover": VBPopover,
  },
  props: ["clientes"],
  data() {
    return {
      currentPage: 1,
      perPage: 30,
      fatura: {},
      clienteDados: {},
      usuariosCliente: [],
      financeiroCliente: [],
      spinner: false,
      mqShallShowLeftSidebar: true,
      todosSelecionados: false,
      selecionavel: false,
      selected: [],
      Excluidos: [],
      configEditar: [],
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      itemsEditar: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["num", "forma"],
      boxTwo: "",

      fields: [
        { key: "checkbox", label: "Todos", thStyle: { width: "2%" } },
        { key: "id_company", label: "ID", thStyle: { width: "2%" } },
        {
          key: "cliente",
          label: "Cliente",
          sortable: true,
          thStyle: { width: "20%" },
        },
        {
          key: "email_empresa",
          label: "E-mail",
          sortable: true,
          thStyle: { width: "100px" },
        },
        {
          key: "plano_nome",
          label: "Plano",
          sortable: true,
          thStyle: { width: "" },
        },
        {
          key: "tipo",
          label: "Tipo Conta",
          sortable: true,
          thStyle: { width: "px" },
        },
        {
          key: "conta",
          label: "Conta",
          sortable: true,
          thStyle: { width: "px" },
        },
        {
          key: "data_inicio",
          label: "Data Início",
          sortable: true,
          thStyle: { width: "px" },
        },
        {
          key: "data_expiracao",
          label: "Expiração",
          thStyle: { width: "px" },
        },
        { key: "opcoes", label: "Opções", thStyle: { width: "80px" }, sortable: false },
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    };
  },
  computed: {
    ...mapState({
      filtroPesquisa: (state) => state.receitas.filtroPesquisar,
      conta_tipo: (state) => state.clientes.tipo_conta,
      busy: (state) => state.clientes.busy,
      user: (state) => state.user.user,
    }),

    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    // this.$store.dispatch("empresas/listaEmpresas");
  },

  methods: {
    selecionaTodos() {
      if (this.todosSelecionados) {
        this.todosSelecionados = false;
        this.clearSelected();
      } else {
        this.todosSelecionados = true;
        this.selectAllRows();
      }
    },
    liberarCliente(item, index) {
      this.$bvModal
        .msgBoxConfirm("Deseja realmente liberar esse cliente?", {
          title: "Liberar cliente",
          size: "sm",
          okVariant: "primary",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http
              .get(
                "adm/administracao/clientes/liberar-cliente/" + item.id_company
              )
              .then((res) => {
                item.data_expiracao = this.$getDate.hoje();
                let payload = {
                  index: index,
                  dados: item,
                };
                this.$store.commit("clientes/EDITAR_CLIENTE", payload);
              });
          }
        });
    },
    abrirWhatsapp(item) {
      if (item.celular_um === null && item.celular_dois === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notificação",
            text: "Esse cliente não possui nenhum celular cadastrado!",
            variant: "danger",
            icon: "MessageCircleIcon",
          },
        });
      } else {
        let nFormatado = "";
        if (item.celular_dois === null) {
          nFormatado = item.celular_um.replace(/\D/gim, "");
        } else {
          nFormatado = item.celular_dois.replace(/\D/gim, "");
        }
        window.open(
          `https://api.whatsapp.com/send?phone=55${nFormatado}`,
          "_blank"
        );
      }
    },
    abrirModalLogar() {
      this.$refs["modal-logar"].show();
    },
    fecharModalLogar() {
      this.$refs["modal-logar"].hide();
    },
    abrirModalHistorico(item) {
      this.clienteDados = item;
      this.$store.dispatch(
        "atendimento/listaAtendimentos",
        this.clienteDados.id_company
      );
      this.$bvModal.show("historicoSuporte");
    },
    abrirModalMigracao(item) {
      this.clienteDados = item;
      this.$store.dispatch("migracao/listaMigracao", item.id_company);
      this.$bvModal.show("migracao");
    },
    logarConta(item) {
      let payload = {
        id_company: item.id_company,
        id_usuario: this.user.id,
      };
      this.$http
        .post("adm/administracao/clientes/logar", payload)
        .then((res) => {
          if (res.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Login realizado com Sucesso!",
                icon: "InfoIcon",
                variant: "success",
              },
            });
            this.$store.dispatch("user/listaUser");
            this.fecharModalLogar();
          }
        });
    },
    abrirModalUsuarios(item) {
      this.clienteDados = item;
      this.$http
        .get("adm/administracao/clientes/usuarios/" + item.id_company)
        .then((res) => {
          this.usuariosCliente = res.data;
          this.$bvModal.show("usuariosCliente");
        });
    },
    abrirModalFinanceiro(item) {
      this.clienteDados = item;
      this.$http
        .get("adm/administracao/clientes/financeiro-cliente/" + item.id_company)
        .then((res) => {
          this.financeiroCliente = res.data;
          this.$bvModal.show("financeiroCliente");
        });
    },
    paginacao() {
      this.$store.commit("clientes/STORE_PAGINACAO", this.currentPage);
      this.$store.dispatch('clientes/listaClientes')
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    buscaDados() {},
  },

  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataDataSemHora(data) {
      if (!data) return;

      return moment(data).format("DD/MM/YYYY");
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
    formataDiaMes(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[1]}/${d[2]}`;
    },

    filtroDesconto(desconto, tipo) {
      if (desconto <= 0) return "0";
      let tipoDesc = "%";

      if (tipo == 1) {
        tipoDesc = "R$";
      }
      return tipoDesc + " " + desconto;
    },
  },
};
</script>
<style scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;
}
.todo-task-list .todo-item:hover {
  visibility: visible;
}
.b-table-row-selected {
  background-color: #0b5661;
  color: #fff;
}

.espacoTd {
  width: 30px;
}
</style>
