<template>
  <div>
    <b-row class="mb-1">
      <b-col>
        <label>Tipo Conta</label>
        <b-input-group size="sm">
          <b-form-select
            id="tipo"
            v-model="conta_tipo"
            :options="tipo_conta"
            text-field="text"
            value-field="value"
            style="height: 32px"
            @change="alterouTipoConta"
          >
          </b-form-select>
        </b-input-group>
      </b-col>

      <b-col md="auto">
        <label>Tipo Data</label>
        <b-input-group size="sm">
          <b-form-select
            id="tipo"
            v-model="data_tipo"
            :options="tipo_data"
            text-field="text"
            value-field="value"
            style="height: 32px"
            @change="alterouTipoData"
          >
          </b-form-select>
        </b-input-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Período"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="periodo"
              :options="periodos"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouPeriodo"
            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicio"
              size="sm"
              v-model="data_inicio"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <label label-for="busca">Busca</label>
        <b-form-group
          id="busca"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-dropdown
                :text="filtroSelecionado"
                variant="outline-primary"
                size="sm"
              >
                <b-dropdown-item
                  @click="(filtroSelecionado = 'ID'), (filter = '')"
                  >ID</b-dropdown-item
                >
                <b-dropdown-item
                  @click="(filtroSelecionado = 'Nome'), (filter = '')"
                  >Nome</b-dropdown-item
                >
                <b-dropdown-item
                  @click="(filtroSelecionado = 'E-mail Empresa'), (filter = '')"
                  >E-mail Empresa</b-dropdown-item
                >
              </b-dropdown>
            </b-input-group-prepend>

            <b-form-input
              id="filterInput"
              v-model="filter"
              debounce="500"
              type="search"
              placeholder="ID, E-mail Empresa ou E-mail Usuário"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },

  data() {
    return {
      filtroSelecionado: "ID",
      filter: "",
      conta_tipo: 0,
      periodo: 3,
      data_tipo: 1,
      data_inicio: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      fornSelIndex: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      tipo_conta: [
        { value: 0, text: "Todos" },
        { value: 4, text: "Teste Grátis" },
        { value: 1, text: "Mensal" },
        { value: 3, text: "Anual" },
      ],
      tipo_data: [
        { value: 1, text: "Data Cadastro" },
        { value: 2, text: "Data Expiração" },
        { value: 3, text: "Data Cancelamento" },
      ],
      periodos: [
        { value: 0, text: "Mostrar Todos" },
        { value: 1, text: "Hoje" },
        { value: 2, text: "Esta Semana" },
        { value: 3, text: "Este mês" },
        { value: 4, text: "Próximos 30 dias" },
        { value: 5, text: "Outro Período" },
      ],
    };
  },

  computed: {},
  mounted(){
    this.limpaFiltros();
  },
  watch: {
    filter() {
      this.buscaDados();
    },
  },

  methods: {
    alterouTipoConta() {
      this.$store.commit("clientes/STORE_TIPO_CONTA", this.conta_tipo);
      this.$store.dispatch("clientes/listaClientes");
    },
    alterouTipoData() {
      this.$store.commit("clientes/STORE_TIPO_DATA", this.data_tipo);
      this.$store.dispatch("clientes/listaClientes");
    },
    alterouPeriodo() {
      if (this.periodo === 0) {
        this.data_inicio = "";
        this.data_final = "";
      } else if (this.periodo === 1) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.hoje();
      } else if (this.periodo === 2) {
        this.data_inicio = moment().day(0).format("YYYY-MM-DD"); // domingo desta semana
        this.data_final = moment().day(6).format("YYYY-MM-DD"); // sábado desta semana
      } else if (this.periodo === 3) {
        this.data_inicio = moment().startOf("month").format("YYYY-MM-DD");
        this.data_final = moment().endOf("month").format("YYYY-MM-DD");
      } else if (this.periodo === 4) {
        this.data_inicio = this.$getDate.hoje();
        this.data_final = this.$getDate.addDias(null, 30);
      } else if (this.periodo === 5) {
        return;
      }
      let payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,  
      };
      this.$store.commit("clientes/STORE_FILTRO_DATA", payload);
      this.$store.dispatch("clientes/listaClientes");
    },
    alterouData() {
      let payload = {
        data_inicio: this.data_inicio,
        data_final: this.data_final,
      };
      this.$store.commit("clientes/STORE_FILTRO_DATA", payload);
      this.$store.dispatch("clientes/listaClientes");
    },
    buscaDados() {
      let payload = {
        tipo_busca: this.filtroSelecionado,
        filter: this.filter,
      };
      this.$store.commit("clientes/STORE_TIPO_BUSCA", payload);
      this.$store.dispatch("clientes/listaClientes");
    },
    limpaFiltros() {
      this.filtroSelecionado = "ID"
      this.filter = ""
      this.conta_tipo = 0
      this.periodo = 3
      this.data_tipo = 1
      this.data_inicio = this.$getDate.primeiroDiaMes()
      this.data_final = this.$getDate.ultimoDiaMes()
      this.$store.commit("clientes/STORE_TIPO_CONTA", this.conta_tipo);
      this.$store.commit("clientes/STORE_TIPO_DATA", this.data_tipo);
      let payload = { data_inicio: this.data_inicio, data_final: this.data_final }
      this.$store.commit("clientes/STORE_FILTRO_DATA", payload);
      this.$store.commit("clientes/STORE_FILTRO_DATA", payload);
      let payloadTipoBusca = { tipo_busca: this.tipo_busca, filter: this.filter}
      this.$store.commit("clientes/STORE_TIPO_BUSCA", payloadTipoBusca);
    }
  },

  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.sidebar-left {
  width: 290px;
}
.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  width: 282px !important;
}
</style>
