<template>
  <b-modal
    id="usuariosCliente"
    no-close-on-backdrop
    no-close-on-esc
    centered
    hide-footer
    size="lg"
    :title="'Usuários ' + clienteDados.cliente"
  >
  <b-card no-body>
    <h6 class="m-1 text-success">
      <feather-icon icon="UserCheckIcon" size="15"/>
     <span style="padding-left: 5px; padding-top: 5px">Usuários Ativos</span>
    </h6>
    <b-table
      striped
      hover
      small
      responsive
      :items="usuariosAtivos"
      :fields="fields"
    >
    </b-table>
    </b-card>
    <b-card no-body>
      <h6 class="m-1 text-danger">
      <feather-icon icon="UserXIcon" size="15"/>
     <span style="padding-left: 5px; padding-top: 5px">Usuários Excluídos</span>
    </h6>
    <b-table
      striped
      hover
      small
      responsive
      :items="usuariosExcluidos"
      :fields="fields"
    >
    </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import { BModal, BCard, BTable, } from "bootstrap-vue";
export default {
  props: ["clienteDados", "usuariosCliente"],
  components: {
    BModal,
    BCard,
    BTable,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nome" },
        { key: "email", label: "E-mail" },
      ],
    }
  },
  computed: {
    usuariosAtivos() {
      return this.usuariosCliente.filter(el => el.deleted_at === null)
    },
    usuariosExcluidos() {
      return this.usuariosCliente.filter(el => el.deleted_at !== null)
    }
  }
};
</script>

<style>
</style>